import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import WirUeberUnsSubmenu from "../../components/wirueberuns/wirueberuns_submenu"
import WirUeberUnsContentHeader from "../../components/wirueberuns/wirueberuns_contentHeader"
import TermineMargin from "../../components/termine-margin"
import SchwerpunktePic from "../../images/schwerpunkte_img.jpg"
import { graphql } from "gatsby"

export const query = graphql`
  {
    allSanitySiteSettings {
      nodes {
        motto
      }
    }
  }
`


const schwerpunkte = ({data}) => (
  <Layout>
    <SEO title="Schwerpunke"/>

    <WirUeberUnsContentHeader headline="UNSERE SCHWERPUNKTE" motto={data.allSanitySiteSettings.nodes[0].motto}/>

    <section id="content_2_schule">
      <WirUeberUnsSubmenu/>

      <article id="con_2_rechts">

        <h3 className="small_sub">
          <span style={{ color: "#007c34" }}>P&Auml;DAGOGISCHE SCHWERPUNKTE</span>
        </h3>

        <p className="text">
          <span style={{ fontWeight: "bold" }}>Man kann niemanden etwas lehren, man kann ihm nur helfen, es in sich selbst zu finden.&ldquo;
            <br/>
          </span>(Galileo Galilei)<br/><br/>

          Mit unserem Konzept wollen wir genau da ansetzen. Wir m&ouml;chten die Vielfalt an F&auml;higkeiten und
          Interessen Ihrer Kinder und unsere unterschiedlichen Lehrerpers&ouml;nlichkeiten zusammenbringen, <br/>um
          die Kinder da zu fordern, wo sie sich weiterentwickeln und genau dazu f&ouml;rdern, wo sie Hilfe
          ben&ouml;tigen.
          Das Lehrerteam lebt dieses Konzept durch die doppelte Klassenf&uuml;hrung, durch Fachteams und die
          Unterst&uuml;tzung unserer Sozialp&auml;dagogin.
        </p><br/><br/>
        <div id="schwerpunkte_img">
          <img src={SchwerpunktePic} width="622" height="600"/>
        </div>
      </article>

      <TermineMargin/>
    </section>
  </Layout>
)

export default schwerpunkte